@use '../../styles/abstracts';

.swiper {
    height: 500px;
    width: 100%;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    :global(.swiper-button-next),
    :global(.swiper-button-prev) {
        &:after {
            font-size: 24px;
        }
        color: abstracts.$green-dark;
    }
    .swiperSlide {
        position: relative;
    }
    .textContainer {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: abstracts.$flat-image-overlay;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.aBullet {
    display: inline-block;
    margin-right: 4px;
    background: none;
    width: 10px;
    height: 10px;
    border: solid 1px abstracts.$white;
    border-radius: 50%;
    cursor: pointer;
}

.aBulletActive {
    background: abstracts.$green-dark;
    border: none;
}

.richtextContainer {
    color: abstracts.$white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 18px;
    padding: 16px 48px;
}

.textLink {
    text-decoration: none;
    color: abstracts.$white;
    background: abstracts.$green-dark;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 120px;
    padding-left: 8px;

    .readMore {
        padding-bottom: 2px;
    }

    svg {
        height: 24px;
        width: 24px;
        fill: abstracts.$white;
        path {
            fill: abstracts.$white;
        }
    }
}
