@use '../../styles/abstracts';

.bold {
    font-weight: 700;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.h1 {
    font-size: 48px;
    font-weight: 700;
    @include abstracts.breakpoint(md, down) {
        font-size: 40px;
    }
    @include abstracts.breakpoint(sm, down) {
        font-size: 32px;
    }
}

.h2 {
    font-size: 32px;
    font-weight: 400;
}

.h3 {
    font-size: 24px;
    font-weight: 400;
}

.imageCenter {
    display: block;
    margin: 0px auto;
}
