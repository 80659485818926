@use './variables';

// ------ BREAKPOINTS ------
@mixin breakpoint($breakpoint, $direction) {
    @if map-has-key(variables.$grid-breakpoints, $breakpoint) {
        // Get breakpoint value.
        $breakpoint-value: map-get(variables.$grid-breakpoints, $breakpoint);

        @if $direction == down {
            @media (max-width: ($breakpoint-value - 1)) {
                @content;
            }
        } @else if $direction == up {
            @media (min-width: $breakpoint-value) {
                @content;
            }
        } @else {
            @media ($direction: $breakpoint-value) {
                @content;
            }
        }

        // If the breakpoint doesn't exist.
    } @else {
        @if $direction == down {
            @media (max-width: $breakpoint) {
                @content;
            }
        } @else if $direction == up {
            @media (min-width: $breakpoint) {
                @content;
            }
        } @else {
            @media ($direction: $breakpoint) {
                @content;
            }
        }
    }
}
