@use '../abstracts';

body {
    margin: 0px;
    padding: 0px;
    background-color: abstracts.$grey-lightest;
    font-family: 'Open Sans';
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
