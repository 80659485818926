@use '../../styles/abstracts';

.heroContainer {
    width: 100%;
    position: relative;
}

.text {
    font-size: 32px;
    color: abstracts.$white;
    font-weight: 600;
    padding: 72px 32px;
    display: flex;
    justify-content: center;
    text-align: center;

    background-color: abstracts.$flat-image-overlay;
}

.bgImage {
    background-color: abstracts.$grey-dark;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
