@use '../../styles/abstracts';

.footerBar {
    height: 48px;
    background-color: abstracts.$grey-darkest;
    color: abstracts.$white;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    align-items: center;

    svg {
        height: 24px;
        width: auto;
    }

    a {
        margin-right: 24px;
        text-decoration: none;
        color: abstracts.$white;
        display: flex;
        justify-content: center;

        &:hover {
            color: abstracts.$green-dark;
            path {
                fill: abstracts.$green-dark;
            }
        }
    }
}

.footerBarRefContainer {
    background-color: abstracts.$grey-dark;
    color: abstracts.$white;
    display: flex;
    padding: 32px 0px;
    justify-content: center;
    @include abstracts.breakpoint(md, down) {
        flex-direction: column;
    }
    > div {
        margin: 16px 40px;
        display: flex;
        flex-direction: column;
    }

    a {
        text-decoration: none;
        color: abstracts.$white;
        font-size: 14px;
        &:hover {
            color: abstracts.$green-dark;
            path {
                fill: abstracts.$green-dark;
            }
        }
    }

    .title {
        font-size: 20px;
        padding-bottom: 4px;
        margin-bottom: 8px;
        border-bottom: 2px solid abstracts.$green-dark;
    }
}
