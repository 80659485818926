@use '../../styles/abstracts';

$totalBarHeight: var(--main-navigation-height);
$borderHeight: 1px;
$barHeight: calc($totalBarHeight - $borderHeight);
$borderHeightSelected: 4px;

ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
}

li {
    a {
        svg {
            height: calc($barHeight - ($borderHeightSelected * 2));
            width: auto;
        }
        padding: 0px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-weight: 700;
        color: abstracts.$night;
        text-decoration: none;
        border-bottom: $borderHeightSelected solid transparent;
        &:hover,
        &:global(.active):hover {
            color: abstracts.$green-dark;
            background-color: abstracts.$grey-light;
            border-bottom: $borderHeightSelected solid abstracts.$green-dark;
        }
        &:global(.active) {
            color: abstracts.$green-dark;
            border-bottom: $borderHeightSelected solid abstracts.$green-dark;
        }
    }
}

.navContainer {
    --color-icon-primary: #cc0000;
    --color-icon-secondary: #31219c;
    height: $barHeight;
    width: 100%;
    overflow: hidden;
    background-color: abstracts.$white;
    border-bottom: $borderHeight solid abstracts.$grey-light;
}

.showDesktop {
    display: none;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    @include abstracts.breakpoint(md, up) {
        display: flex;
    }
}

.showMobile {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    align-items: center;
    padding-right: 32px;
    @include abstracts.breakpoint(md, up) {
        display: none;
    }
}

.menuIcon {
    padding: 4px; // make the icon easier to click
    border: none;
    background-color: unset;
    &:hover {
        cursor: pointer;
    }
    svg {
        fill: abstracts.$night;
        height: 32px;
        width: 32px;
    }
}

.logoTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;
    text-transform: uppercase;
    .logoTitle {
        font-size: 28px;
        color: abstracts.$green-dark;
    }
    .logoBottomLine {
        font-size: 10px;
        letter-spacing: 2px;
        color: abstracts.$night;
    }
}

.clickOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: transparent;
    bottom: 0px;
    height: calc(100% - $totalBarHeight);
    display: none;
    flex-direction: column;
    &.display {
        display: block;
    }
}

.sideMenu {
    @include abstracts.breakpoint(md, up) {
        display: none;
    }
    position: absolute;
    z-index: 100;
    overflow: hidden;
    height: calc(100% - $totalBarHeight);
    right: 0px;
    background-color: abstracts.$white;
    transition: width 0.2s linear;
    width: 0;
    &.display {
        width: 320px;
        padding: 0px 8px;
    }
    ul {
        flex-direction: column;
        font-size: 20px;
    }
    a {
        margin: 16px;
        border-bottom: 2px solid abstracts.$grey-light;
        &:hover,
        &:global(.active):hover {
            color: abstracts.$green-dark;
            background-color: abstracts.$grey-light;
            border-bottom: 2px solid abstracts.$green-dark;
        }
        &:global(.active) {
            color: abstracts.$green-dark;
            border-bottom: 2px solid abstracts.$green-dark;
        }
    }
}
