@use '../abstracts';

/* Open Sans fonts normal */
@font-face {
    font-family: abstracts.$open-sans;
    src: local(abstracts.$open-sans), url('../../fonts/OpenSans-VariableFont.ttf');
    font-weight: 300 800;
    font-stretch: 75% 100%;
    font-style: normal;
}
/* Open Sans fonts italic */
@font-face {
    font-family: abstracts.$open-sans;
    src: local(abstracts.$open-sans), url('../../fonts/OpenSans-Italic-VariableFont.ttf');
    font-weight: 300 800;
    font-stretch: 75% 100%;
    font-style: italic;
}
