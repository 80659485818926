@use '../../styles/abstracts';

.textContainer {
    padding: 104px;
    @include abstracts.breakpoint(lg, down) {
        padding: 64px;
    }
    @include abstracts.breakpoint(md, down) {
        padding: 32px 64px;
    }
    @include abstracts.breakpoint(sm, down) {
        padding: 16px 32px;
    }
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    h5,
    h2 {
        margin: 0px 0px 8px 0px;
        padding: 0px;
        color: abstracts.$grey-dark;
        font-size: 32px;
    }

    h5 {
        font-weight: 600;
        font-size: 18px;
        color: abstracts.$grey-medium;
    }
}

.imageContainer {
    overflow: hidden;
    width: 100%;

    img {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 360px;
        object-fit: cover;
    }
}

.mapContainer {
    overflow: hidden;
    iframe {
        min-height: 370px;
        height: 100%;
        width: 100%;
        border: 0px;
    }
}
