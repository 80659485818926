@use '../../styles/abstracts';

.bgText {
    background-color: abstracts.$grey-lightest;
    padding: 24px 0px;
}

.bg {
    background-color: abstracts.$grey-lightest;
}

.accordionContainer {
    margin: 24px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.accordionSummary {
    font-weight: 600;
    @include abstracts.breakpoint(sm, up) {
        &.active:hover {
            background-color: abstracts.$green-darker;
        }
        &:hover {
            background-color: abstracts.$green-dark;
        }
    }

    &.active {
        background-color: abstracts.$green-dark;
    }
}

.fileListContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
}

.listItem {
    width: 100%;
    border: 1px solid abstracts.$grey-light;
    background-color: abstracts.$grey-lightest;
    border-radius: 4px;

    &:hover {
        background-color: abstracts.$grey-light;
    }
}
