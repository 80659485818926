@use '../../styles/abstracts';

.bg {
    background-color: abstracts.$white;
    padding: 24px 0px;
}

.card {
    padding: 24px 16px;
}

.imageContainer {
    position: relative;
    margin: 0px 32px 8px 32px;
    aspect-ratio: calc(12 / 16);
    .imageType {
        transition:
            width 0.5s,
            height 0.5s;
        position: absolute;
        background-color: abstracts.$green-dark;
        color: abstracts.$white;
        right: 8px;
        top: 0px;
        height: 56px;
        width: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 32px;
            width: 32px;
            color: abstracts.$white;
            path,
            polygon,
            rect,
            circle {
                fill: abstracts.$white;
            }
        }
    }

    .cardImage {
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .titleText {
        display: none;
        text-align: center;
        font-size: 10px;
        @media (min-width: 400px) {
            font-size: 12px;
        }
        @media (min-width: 440px) {
            font-size: 14px;
        }
        @media (min-width: 480px) {
            font-size: 16px;
        }
        @media (min-width: 520px) {
            font-size: 18px;
        }
        @media (min-width: 560px) {
            font-size: 20px;
        }
    }

    &:hover {
        .imageType {
            background-color: abstracts.$green-dark;
            right: 0px;
            height: 100%;
            width: 100%;

            svg {
                display: none;
            }
        }

        .titleText {
            display: block;
        }
    }
}

.cardImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 15px;
    h3 {
        margin: 0px 0px 16px 0px;
    }
}
