@use '../abstracts';

:root {
    --blue: #{abstracts.$blue};
    --blue-medium: #{abstracts.$blue-medium};
    --blue-light: #{abstracts.$blue-light};

    --green-dark: #{abstracts.$green-dark};
    --green: #{abstracts.$green};
    --green-medium: #{abstracts.$green-medium};
    --green-light: #{abstracts.$green-light};

    --yellow: #{abstracts.$yellow};
    --yellow-medium: #{abstracts.$yellow-medium};
    --yellow-light: #{abstracts.$yellow-light};

    --orange: #{abstracts.$orange};
    --orange-medium: #{abstracts.$orange-medium};
    --orange-light: #{abstracts.$orange-light};

    --purple: #{abstracts.$purple};
    --purple-medium: #{abstracts.$purple-medium};
    --purple-light: #{abstracts.$purple-light};

    --red-dark: #{abstracts.$red-dark};
    --red: #{abstracts.$red};
    --red-medium: #{abstracts.$red-medium};
    --red-light: #{abstracts.$red-light};

    --night: #{abstracts.$night};
    --grey-darkest: #{abstracts.$grey-darkest};
    --grey-dark: #{abstracts.$grey-dark};
    --grey: #{abstracts.$grey};
    --grey-medium: #{abstracts.$grey-medium};
    --grey-light: #{abstracts.$grey-light};
    --grey-lightest: #{abstracts.$grey-lightest};
    --white: #{abstracts.$white};

    --flat-image-overlay: #{abstracts.$flat-image-overlay};

    /* Generate classNames from abstracts.$colors list*/
    @each $color, $value in abstracts.$colors {
        .c-#{$color} {
            background: $value;
        }
    }

    @each $color, $value in abstracts.$colors {
        .c-#{$color}-text {
            color: $value;
        }
    }
}
