@use '../../styles/abstracts';

.videoCarouselContainer {
    background-color: abstracts.$white;
    padding: 24px 0px;
}

.swiper {
    width: 100%;
    :global(.swiper-button-next),
    :global(.swiper-button-prev) {
        &:after {
            font-size: 32px;
        }
        color: abstracts.$green-dark;
    }
    .swiperSlide {
        position: relative;
        overflow: hidden;
        width: 100%;
        .youtubePlayer {
            aspect-ratio: calc(16 / 9);
            width: 100%;
        }
        .pagination {
            height: 28px;
        }
    }
}

.aBullet {
    display: inline-block;
    margin-right: 4px;
    background: none;
    width: 10px;
    height: 10px;
    border: solid 1px abstracts.$green-dark;
    border-radius: 50%;
    cursor: pointer;
}

.aBulletActive {
    background: abstracts.$green-dark;
    border: none;
}
