//Fonts
$open-sans: 'Open Sans';

//Colors
//Primary colors
$blue: #004c97;
$blue-medium: #0e87ff;
$blue-light: #83c2ff;

$green-darker: #457b34;
$green-dark: #538f40;
$green: #00b140;
$green-medium: #27df6a;
$green-light: #9dffc1;

//Secondary colors
$yellow: #ffcc00;
$yellow-medium: #ffe476;
$yellow-light: #fff7d8;

$orange: #f09316;
$orange-medium: #f7c685;
$orange-light: #fcf2e1;

$purple: #8a1a67;
$purple-medium: #db3faa;
$purple-light: #eea2d6;

$red-dark: #cc0000;
$red: #df1c3c;
$red-medium: #ef8193;
$red-light: #fceaed;

//Neutrals colors
$night: #00182f;
$grey-darkest: #272930;
$grey-dark: #34363e;
$grey: #505b73;
$grey-medium: #a5acbb;
$grey-light: #e4e8f0;
$grey-lightest: #f7f9fc;
$white: #ffffff;

$make-color-darker-8: rgba(0, 0, 0, 0.08);

$flat-image-overlay: rgba(83, 143, 64, 0.3);

//all colors map
$colors: (
    'blue': $blue,
    'blue-medium': $blue-medium,
    'blue-light': $blue-light,
    'green': $green,
    'green-medium': $green-medium,
    'green-light': $green-light,
    'yellow': $yellow,
    'yellow-medium': $yellow-medium,
    'yellow-light': $yellow-light,
    'orange': $orange,
    'orange-medium': $orange-medium,
    'orange-light': $orange-light,
    'purple': $purple,
    'purple-medium': $purple-medium,
    'purple-light': $purple-light,
    'red': $red,
    'red-medium': $red-medium,
    'red-light': $red-light,
    'night': $night,
    'grey': $grey,
    'grey-medium': $grey-medium,
    'grey-light': $grey-light,
    'grey-lightest': $grey-lightest,
    'white': $white,
    'flat-image-overlay': $flat-image-overlay
);

// Grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
